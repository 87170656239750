// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recon-design-system-js": () => import("./../../../src/pages/recon_design_system.js" /* webpackChunkName: "component---src-pages-recon-design-system-js" */),
  "component---src-pages-seamoss-ecommerce-js": () => import("./../../../src/pages/seamoss_ecommerce.js" /* webpackChunkName: "component---src-pages-seamoss-ecommerce-js" */),
  "component---src-pages-tafheem-us-sunnah-js": () => import("./../../../src/pages/tafheem_us_sunnah.js" /* webpackChunkName: "component---src-pages-tafheem-us-sunnah-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

